<template>
	<div id="basciSet" class="el-content">
		<a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
			<a-form-item label="队列启动">
				<a-space>
					<!-- <a-tag :color="duilie.status == 1 ? '#00CC66' : '#999'">{{ duilie.status == 1 ? '运行中' : '未启动' }}</a-tag> -->
					<a-button type="primary" @click="handleQueue('start')"><i class="ri-play-fill"></i>返现队列</a-button>
					<a-button type="danger" @click="handleQueue('close')"><i class="ri-pause-mini-fill"></i>关闭返现队列</a-button>
					<a-button @click="downloadQueue"><i class="ri-download-cloud-2-fill"></i>下载队列日志</a-button>
					<a-button type="primary" @click="getQueueList">查看已启动的队列</a-button>
				</a-space>

				<div class="bs-duilie" v-if="queueList.length > 0">
					<ul>
						<li v-for="(item,index) in queueList " :key="index">
							<span class="f12">名称：</span> 
							<span class="bs-duilie-name">{{item.queue}}</span>  
							<a-tag :color=" item.code ? '#00CC66':'#999'">{{ item.code ? '已启动':'未启动'}}</a-tag>
						</li>
					</ul>

				</div>

			</a-form-item>
			<a-form-item>
				<template #label>
					<span>通讯状态</span>
					<a-tooltip title="通讯占用端口:5044"><i class="ri-information-line ri-top"></i></a-tooltip>
				</template>
				<a-space>
					<a-tag :color="im.status == 1 ? '#00CC66' : '#999'">{{ im.status == 1 ? '运行中' : '未启动' }}</a-tag>
					<a-button type="primary" @click="changeImStatus(1)"><i class="ri-play-fill"></i>启动通讯</a-button>
					<a-button type="danger" @click="changeImStatus(2)"><i class="ri-pause-mini-fill"></i>停止通讯</a-button>
					<a-button @click="changeImStatus(3)"><i class="ri-refresh-line"></i>重启通讯</a-button>
				</a-space>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import saasSetModel from '@/api/saas/set';
import siteinfo from '@/util/global'
export default {
	setup(){
		const state = reactive({
			form: {},
			im: {
				status: 0 //0未启动 1运行中
			},
			duilie: {
				status: 0
			},
			queueList:[],
		})
		getInitStatus()
		function getInitStatus(){
			saasSetModel.getImStatus(res=>state.im = res.status)
			// saasSetModel.getQueueStatus(res=>state.duilie.status = res)
		}

		function getQueueList(){
			saasSetModel.getQueueStatus(res=>state.queueList = res.command)
		}

		const handleQueue = type=>saasSetModel.handleQueue(type)

		const changeImStatus = type=>saasSetModel.handleImStatus(type,()=>getInitStatus())

		function downloadQueue() {
			let t = this;
			let url =siteinfo.siteinfo.siteroot + '/web/getQueueLog?token=' + localStorage.getItem('token');
			window.open(url);
		}

		return{
			...toRefs(state),
			getInitStatus,
			handleQueue,
			downloadQueue,
			changeImStatus,
			getQueueList
		}
	}
}
</script>

<style lang="scss" scoped>
.bs-duilie{
	border: 1px solid #f4f4f4;
	padding: 12px;
	margin-top: 12px;

	ul{
		list-style: none;
		padding: 0;
		margin: 0;
	}

	&-name{
		font-weight: bold;
		margin-right: 12px;
	}
}
</style>
